<template>
  <div class="menus">
	  <div id="">
	  	 <el-form  ref="queryForm"  :inline="true" label-width="60px" style="width: 100%;text-align:left;">
					<el-form-item label="考试ID " prop="roleName" >
					 
                      <el-select size="small" v-model="queryParams.eid" placeholder="请选择">
                        <el-option label="测试" value="0"> </el-option>
                        <el-option label="练习" value="1"> </el-option>
                        <el-option label="考试" value="2"> </el-option>
                      </el-select>
					</el-form-item>
				    <el-form-item label="电话" prop="roleKey">
                          <el-input
					    v-model="queryParams.bumencId"
					    placeholder="请输入标题"
					    clearable
					    size="small"
					    style="width: 200px"
					    @keyup.enter.native="handleQuery"
					  />
					</el-form-item>
					<el-form-item label="" prop="status">
					  <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
					  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
					</el-form-item>
	  	 </el-form>
	  </div>
     

     
    <div class="tian">
        <!-- <el-button type="primary"  plain icon="el-icon-plus" size="mini" @click="addition()" style=" float:left;">添加</el-button> -->

        <el-button icon="el-icon-download" size="mini" type="warning" plain style=" float:left;" @click="daochu()">导出</el-button>
    </div>

    <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}"
        @selection-change="handleSelectionChange"
        >
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column prop="title"  label="标题"> </el-table-column>
        <el-table-column prop="mobile"   label="电话"> </el-table-column>
        <el-table-column prop="realname"  label="姓名"> </el-table-column>
        <el-table-column prop="mark"  label="分数"> </el-table-column>
        <el-table-column prop="timenum"  label="用时"> </el-table-column>
        <el-table-column prop="createtime"  label="创建时间"> </el-table-column>
        
       
        <el-table-column label="操作">
            <template slot-scope="scope">
                <!-- <el-button size="mini" type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row)">编辑</el-button> -->
                <el-button size="mini" type="text"  icon="el-icon-delete"   @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    
  
      <pagination
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="info()"
        />
    </div>
</template>



<script>
// import Pagination from "@/components/Pagination";
import request from '../../../utils/request.js'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  export default {
   components: { Treeselect},
    data() {
      return {
        total:0,
        formLabelWidth: '120px',
        tableData: [],
        id:0,
        dateRange:[],
        queryParams:{
             eid:'',  //搜索名称
             bumencId:'',  //搜索电话
             pageSize: 10, //分页默认一页显示15条数据
             total:0, //共多少条数据从接口获取
             pageNum: 1, //分页默认显示第1页数据
        },
        clickMsg:'1', //搜索数据分页和查询全部数据分页开关 1 查询所有数据  2 搜索数据  3 角色查询
        nstitution:[],
        articleId:'',
        multipleSelection: []
      }
    },
    created() {
        this.info();
        // this.getList();
    },
     methods:{
          handleSelectionChange(val) {
             this.multipleSelection = val.map(item => item.id);
            //  console.log(val)
            //  console.log(this.multipleSelection)
          },
       normalizer(node) {
            if (node.children && !node.children.length) {
                delete node.children;
            }
            return {
                id: node.id,
                label: node.title,
                children: node.children
            };
       },
       //获取列表数据  
      info(){
            this.articleId  = this.$route.query.id;
            let dats={
                pageNum:this.queryParams.pageNum,
                pageSize:this.queryParams.pageSize,
                eid:this.articleId,
                bumencId:this.queryParams.bumencId,
            }
            return request({
                  url:'userExam/queryUserExam',
                  method:'get',
                  params:dats
              }).then(res=>{
                if(res.status == 200){
                    // console.log(res)
                    this.tableData = res.data.data
                    // this.queryParams.pageCount = res.data.pageBean.pageCount; //接口获取到的共多少页
                    this.total = res.data.pageBean.count; //接口获取到的共多少条
                }
              })
              
         },
         daochu(){
            //  download('user/userExportData',this.multipleSelection,`user_${new Date().getTime()}.xls`)
            if(this.multipleSelection == ''){
                this.$message.error('请勾选需要导出的数据');
            }else {
                let dats ={
                    eid:this.articleId,
                    bumencId:this.multipleSelection
                }
                return request({
                    url:'userExam/exportData',
                    method:'get',
                    params:dats
                }).then(res=>{
                    if(res.status == 200){
                        // console.log(res)
                        const link = document.createElement('a') // 首先创建一个a标签毕竟下载是要通过a标签来下载的。
                        let blob = new Blob([res.data], {type: "application/vnd.ms-excel"}) // 第一个参数是后台返回的文件流变量，第二个参数是要转换的类型，由type的值来决定。
                        link.style.display = 'none';
                        link.href = URL.createObjectURL(blob) // 用URL.createObjectURL方法来创建一个URL对象并赋值给a标签的heft属性。
                        link.download = '序列号.xls' // 设置文件名字。
                        document.body.appendChild(link);
                        link.click() // 触发点击事件，开始下载。
                       
                    }
                })
            }
         },
       
		//搜索
		handleQuery() {
          this.info();
          this.queryParams.createTime = this.dateRange[0]
          this.queryParams.endTime = this.dateRange[1]
		},
		//搜索重置
		resetQuery(){
            this.queryParams.types =''
            this.queryParams.title = ''
            this.info();
		},
   
        //删除
          handleDelete(index, row) {
            // console.log( row);
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data ={
                    userExamId:row.id
                }
                return request({
                    url:'userExam/deleteUserExamById',
                    method:'delete',
                    data:data
                }).then(res=>{
                if(res.status == 200){
                      this.$message({
                        message: '删除成功',
                        type: 'success'
                      });
                    this.info();
                 }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        }, 
    },
  }
</script>

<style scoped>
  .el-table .warning-row {
     background: oldlace;
  }

  .el-table .success-row {
     background: #f0f9eb;
  }
  .tian{
        width: 100%;
        height: 60px;
   }
  .el-dialog{
      width: 500px !important;
  }
  .el-form{
        height: auto;
    }
  .el-pagination{
    text-align: right;
  }
</style>
 
